<template>
  <v-container fluid>
    <v-speed-dial
      v-model="fab"
      bottom
      right
      fixed
      open-on-hover
      :direction="'top'"
      :transition="'slide-y-reverse-transition'"
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="primary" dark fab>
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-tooltip left v-if="$root.hasAccess('can_create_vessel')">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            color="green"
            v-on="on"
            @click="dialogForm = {action: 'Create Vessel', showForm: true, component: 'VesselApiForm', vessel: {}}"
          >
            <v-icon>mdi-web</v-icon>
          </v-btn>
        </template>
        <span>Create via API</span>
      </v-tooltip>
      <v-tooltip left v-if="$root.hasAccess('can_create_vessel')">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            color="indigo"
            v-on="on"
            @click="dialogForm = {action: 'Create Vessel', showForm: true, component: 'VesselManualForm', vessel: {}}"
          >
            <v-icon>mdi-incognito</v-icon>
          </v-btn>
        </template>
        <span>Create Manually</span>
      </v-tooltip>
    </v-speed-dial>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          :no-data-text="'No data found'"
          :no-results-text="'No results found'"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
        :sort-by="['id']"
        :sort-desc="[true]"
      >
        <template v-slot:progress>
          <div class="full-width text-center ma-4">
            <v-progress-circular
              indeterminate
              color="primary"
              :size="60"
              :width="5"
            ></v-progress-circular>
          </div>
        </template>
        <template v-if="$root.hasAccess()" v-slot:item.edit="{ item }">
          <v-btn icon @click="dialogForm = {action: 'Edit Vessel', showForm: true, component: 'VesselManualForm', vessel: Object.assign({}, item)}">
            <v-icon>mdi-account-edit</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.vessel_owner="{ item }">
          {{ item.card_id.map(owner => owner.name).join(', ') }}
        </template>
      </v-data-table>
    </v-card>
    <component
      :is="dialogForm.component" 
      :action="dialogForm.action"
      :showForm="dialogForm.showForm"
      :owners="vesselOwners"
      @formSubmitted="resetDialog();getVessels();"
      @closeDialog="resetDialog()"
      :vessel="dialogForm.vessel" />
  </v-container>
</template>

<script>
  import VesselApiForm from './blocks/VesselApiForm';
  import VesselManualForm from './blocks/VesselManualForm';

  export default {
    name: 's-vessel',
    components: {
      VesselApiForm,
      VesselManualForm
    },
    data() {
      return {
        dialogForm: {
          component: '',
          showForm: false,
          action: '',
        },
        fab: false,
        search: '',
        loading: false,
        headers: [],
        items: [],
        vesselOwners: [],
      };
    },
    methods: {
      resetDialog() {
        this.dialogForm = {showForm: false, action: '', component: ''};
      },
      setHeaders() {
        const hasAccess = this.$root.hasAccess();
        const commonHeaders = [
          {text: 'Name', value: 'name', align: 'center'},
          {text: 'Vessel Type', value: 'vessel_type', align: 'center'},
          {text: 'Call Sign', value: 'call_sign', align: 'center'},
          {text: 'IMO Number', value: 'imo_number', align: 'center'},
          {text: 'Flag', value: 'flag', align: 'center'},
          {text: 'Parent Company', value: 'parent_company', align: 'center'},
          {text: 'Registered Ownership', value: 'registered_ownership', align: 'center'},
          {text: 'Vessel Owner', value: 'vessel_owner', align: 'center'},
          {text: 'Gross Tonnage', value: 'gross_tonnage', align: 'center'}
        ];
        if (hasAccess) {
          commonHeaders.push({ text: 'Edit', value: 'edit', align: 'center' });
        }
        this.headers = commonHeaders;
      },
      getVessels() {
        this.loading = true;
        this.$api.vessels.index()
          .then((response) => {
            this.items = response.vessels;
            this.vesselOwners = response.vessel_owners;
            this.loading = false;
          }).catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
    },
    created() {
      this.getVessels();
      this.setHeaders();
    },
  };
</script>