<template>
  <v-container fluid>
    <v-form ref="form" v-model="form" @submit.prevent="submit">
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-switch
            dense
            hide-details
            v-model="serviceOptFormBunker.metadata.arrange_by_owner"
            label="Arrange By Principal?"
            class="mt-0 pt-0"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!serviceOptFormBunker.metadata.arrange_by_owner" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-text-field
            label="Bunker Surveyor Charges"
            placeholder="Enter Bunker Surveyor Charges"
            :rules="amountRules"
            outlined
            dense
            type="number"
            v-model="serviceOptFormBunker.amount"
            prepend-inner-icon="mdi-currency-usd"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!serviceOptFormBunker.metadata.arrange_by_owner">
          <v-select
            label="Bunker Surveyor Supplier"
            placeholder="Select Bunker Surveyor supplier"
            outlined
            dense
            v-model="serviceOptFormBunker.card_id"
            prepend-inner-icon="mdi-account"
            :items="suppliersList"
            :rules="supplierBunkerRules"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!serviceOptFormBunker.metadata.arrange_by_owner" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-select
            label="Tax"
            placeholder="Select Tax"
            outlined
            dense
            v-model="serviceOptFormBunker.metadata.tax"
            prepend-inner-icon="mdi-cash-fast"
            :items="taxes"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-textarea
            label="Remarks"
            placeholder="Enter Remarks"
            outlined
            dense
            hide-details
            :rows="2"
            v-model="serviceOptFormBunker.remarks"
          ></v-textarea>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-btn class="float-right" color="success" outlined :disabled="loading" :loading="loading"
            @click="saveDetails">Save</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
  import rules from '../../../../utilities/validator';
  import _ from 'lodash';

  export default {
    name: 's-services-opted-surveyor',
    props: {
      operation: {
        type: Object,
        default: {},
      },
      isDraft: {
        type: Boolean,
        default: false,
      },
      servicesOpted: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        loading: false,
        form: false,
        rules,
        serviceOptFormBunker: {
          service_catalog_id: null,
          card_id: null,
          amount: 0,
          bill_type: 'Bunker Surveyor',
          metadata: {
            arrange_by_owner: false,
            tax: ''
          },
          remarks: '',
        },
        suppliersList: [],
        taxes: ['0%', '9%']
      };
    },
    methods: {
      submit() {
        return this.$refs.form.validate();
      },
      handleOperationInit() {
        this.populateSuppliers();
      },
      initServiceOptedDetails() {
        const bunkerService = _.find(this.servicesOpted, {bill_type: 'Bunker Surveyor'});
        if (bunkerService) {
          this.serviceOptFormBunker = {
            ...bunkerService,
            amount: bunkerService.amount ? parseFloat(bunkerService.amount).toFixed(2) : 0,
          };
          if (bunkerService.metadata && bunkerService.metadata.arrange_by_owner !== undefined) {
            this.serviceOptFormBunker.metadata.arrange_by_owner = bunkerService.metadata.arrange_by_owner;
          }
        }
      },
      populateSuppliers() {
        this.$api.service_catalogs.get_suppliers_list('Bunker Surveyor')
          .then((response) => {
            this.suppliersList = response.suppliers;
            this.serviceOptFormBunker.service_catalog_id = response.service_catalog_id;
            this.initServiceOptedDetails();
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      saveDetails() {
        if(!this.submit()) return;
        this.loading = true;
        this.serviceOptFormBunker = Object.assign(this.serviceOptFormBunker, {
          operation_id: this.operation.id
        });

        Promise.all([
            this.$api.operation_billed_items.save_service(this.serviceOptFormBunker)
          ]).then(() => {
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Surveyor service updated successfully',
            });
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.loading = false));
      }
    },
    watch: {
      operation(value) {
        this.handleOperationInit();
      }
    },
    computed: {
      amountRules() {
        if (this.serviceOptFormBunker.metadata.arrange_by_owner) {
          return [this.rules.negative];
        } else {
          if (this.isDraft || (this.operation && this.operation.is_draft)) {
            return [this.rules.required, this.rules.negative];
          } else {
            return [this.rules.negative];
          }
        }
      },
      supplierBunkerRules() {
        if (this.serviceOptFormBunker.metadata.arrange_by_owner) {
          return [];
        } else {
          return [this.rules.required];
        }
      },
    },
    created() {
      this.handleOperationInit();
    },
  };
</script>