<template>
  <v-container fluid>
    <v-form ref="form" v-model="form" @submit.prevent="submit">
      <v-row>
        <v-row v-for="(service, index) in otherServices" :key="'service-' + index">
          <v-col cols="12" class="pb-0">
            <span class="text-h6">Other Service: #{{index + 1}}</span>
            <v-btn color="red" icon @click="removeServiceEntry(index, service.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
            <v-switch
              dense
              hide-details
              v-model="service.metadata.arrange_by_owner"
              label="Arrange By Principal?"
              class="mt-0 pt-0"
            ></v-switch>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!service.metadata.arrange_by_owner" v-if="$root.hasAccess('can_manage_operation_amount_details')">
            <v-text-field
              label="Others Charges"
              placeholder="Enter Others Charges"
              :rules="amountRules[index]"
              outlined
              dense
              v-model="service.amount"
              type="number"
              prepend-inner-icon="mdi-currency-usd"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!service.metadata.arrange_by_owner">
            <v-text-field
              label="Supplier"
              placeholder="Enter Supplier Name"
              outlined
              dense
              prepend-inner-icon="mdi-account"
              v-model="service.metadata.supplier"
              :rules="supplierRules[index]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!service.metadata.arrange_by_owner" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-select
            label="Tax"
            placeholder="Select Tax"
            outlined
            dense
            v-model="service.metadata.tax"
            prepend-inner-icon="mdi-cash-fast"
            :items="taxes"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
          <v-col cols="12" class="pb-0">
            <v-textarea
              label="Remarks"
              placeholder="Enter Remarks"
              outlined
              dense
              hide-details
              :rows="2"
              v-model="service.remarks"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-col cols="12" class="pt-7">
          <v-btn color="primary" @click="addOtherServices">Add</v-btn>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-btn class="float-right" color="success" outlined :disabled="loading" :loading="loading"
            @click="saveDetails">Save</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
  import rules from '../../../../utilities/validator';

  export default {
    name: 's-services-opted-others-service',
    props: {
      operation: {
        type: Object,
        default: {},
      },
      isDraft: {
        type: Boolean,
        default: false,
      },
      servicesOpted: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        loading: false,
        form: false,
        rules,
        otherServices: [],
        serviceOptForm: {
          service_catalog_id: null,
          card_id: null,
          amount: 0,
          bill_type: "Others",
          remarks: '',
          metadata: {
            tax: '',
            arrange_by_owner: false,
            supplier: ''
          },
        },
        suppliers: [],
        taxes: ['0%', '9%']
      };
    },
    methods: {
      removeServiceEntry(index, serviceId) {
        if (!serviceId) {
          this.otherServices.splice(index, 1);
          return;
        }
        this.loading = true;
        this.$api.operation_billed_items.remove_service_entry(serviceId, { index })
          .then(() => {
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Service entry removed successfully',
            });
            this.otherServices.splice(index, 1);
          })
          .catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          })
          .finally(() => {
            this.loading = false;
          });
      },
      addOtherServices() {
        const newService = JSON.parse(JSON.stringify(this.serviceOptForm));
        this.otherServices.push(newService);
      },
      submit() {
        return this.$refs.form.validate();
      },
      handleOperationInit() {
        this.populateSuppliers();
      },
      initServiceOptedDetails() {
        const services = _.filter(this.servicesOpted, { bill_type: 'Others' });
        if (services.length > 0) {
          const newServices = services.filter(service => 
            service.metadata?.supplier || service.metadata?.arrange_by_owner === true
          );

          if (newServices.length > 0) {
            this.otherServices = newServices.map(service => ({
              ...service,
              amount: parseFloat(service.amount).toFixed(2),
              metadata: {
                ...service.metadata,
              },
            }));
          } else {
            this.otherServices = [JSON.parse(JSON.stringify(this.serviceOptForm))];
          }
        } else {
          this.addOtherServices();
        }
      },
      populateSuppliers() {
        this.$api.service_catalogs.get_suppliers_list('Others')
          .then((response) => {
            this.suppliers = response.suppliers;
            this.serviceOptForm.service_catalog_id = response.service_catalog_id;
            this.initServiceOptedDetails();
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      saveDetails() {
        if(!this.submit()) return;
        this.loading = true;

        this.otherServices = this.otherServices.map(service => {
          service.service_catalog_id = this.serviceOptForm.service_catalog_id;
          service.operation_id = this.operation.id;

          return service;
        });

        Promise.all([
            ...[...this.otherServices].map(service => {
              const apiType = service.id ? "update" : "save_service";
              return this.$api.operation_billed_items[apiType](service, true)
            })
          ])
          .then(() => {
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Other service updated successfully',
            });
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.loading = false));
      },
    },
    watch: {
      operation(value) {
        this.handleOperationInit();
      },
    },
    computed: {
      amountRules() {
        return this.otherServices.map(service => {
          if (service.metadata.arrange_by_owner) {
            return [this.rules.negative];
          } else {
            if (this.isDraft || (this.operation && this.operation.is_draft)) {
              return [this.rules.required, this.rules.negative];
            } else {
              return [this.rules.negative];
            }
          }
        });
      },
      supplierRules() {
        return this.otherServices.map(service => {
          if (service.metadata.arrange_by_owner) {
            return [];
          } else {
            return [this.rules.required];
          }
        });
      }
    },
    created() {
      this.handleOperationInit();
    },
  };
</script>