<template>
  <v-container fluid>
    <v-form ref="form" v-model="form" @submit.prevent="submit">
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-switch
            dense
            hide-details
            v-model="serviceOptForm.metadata.arrange_by_owner"
            label="Arrange By Principal?"
            class="mt-0 pt-0"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!serviceOptForm.metadata.arrange_by_owner" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-text-field
            label="Garbage Disposal Charges"
            placeholder="Enter Garbage Disposal Charges"
            :rules="amountRules"
            outlined
            dense
            type="number"
            v-model="serviceOptForm.amount"
            prepend-inner-icon="mdi-currency-usd"
          ></v-text-field>
        </v-col>
        <!-- <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-select
            label="Type"
            placeholder="Select Type"
            outlined
            dense
            v-model="serviceOptForm.metadata.type"
            prepend-inner-icon="mdi-food-takeout-box"
            :items="wasteTypes"
            :rules="amountRules"
            @change="onFieldChange()"
          ></v-select>
        </v-col>
        <v-col v-if="showCBMField" cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="CBM"
            placeholder="Enter CBM"
            :rules="[rules.negative]"
            outlined
            dense
            type="number"
            v-model="serviceOptForm.metadata.cbm"
            prepend-inner-icon="mdi-shipping-pallet"
            @change="onFieldChange()"
          ></v-text-field>
        </v-col> -->
        <!-- <v-col v-if="showUnitField" cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="No. of Unit"
            placeholder="Enter No. of Unit"
            :rules="[rules.negative]"
            outlined
            dense
            type="number"
            v-model="serviceOptForm.metadata.unit"
            prepend-inner-icon="mdi-alpha-u-box"
            @change="onFieldChange()"
          ></v-text-field>
        </v-col>
        <v-col v-if="showCartonField" cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="No. of Carton"
            placeholder="Enter No. of Carton"
            :rules="[rules.negative]"
            outlined
            dense
            type="number"
            v-model="serviceOptForm.metadata.carton"
            prepend-inner-icon="mdi-crop-landscape"
            @change="onFieldChange()"
          ></v-text-field>
        </v-col> -->
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!serviceOptForm.metadata.arrange_by_owner">
          <v-select
            label="Supplier"
            placeholder="Select a supplier"
            outlined
            dense
            v-model="serviceOptForm.card_id"
            prepend-inner-icon="mdi-account"
            :items="suppliers"
            :rules="supplierRules"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <!-- <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-text-field
            label="Transportation & Additional Charges"
            placeholder="Enter Transportation & Additional Charges"
            :rules="amountRules"
            outlined
            dense
            type="number"
            v-model="serviceOptForm.metadata.additional_charges"
            prepend-inner-icon="mdi-currency-usd"
          ></v-text-field>
        </v-col> -->
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!serviceOptForm.metadata.arrange_by_owner" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-select
            label="Tax"
            placeholder="Select Tax"
            outlined
            dense
            v-model="serviceOptForm.metadata.tax"
            prepend-inner-icon="mdi-cash-fast"
            :items="taxes"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-textarea
            label="Remarks"
            placeholder="Enter Remarks"
            outlined
            dense
            hide-details
            :rows="2"
            v-model="serviceOptForm.remarks"
          ></v-textarea>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-btn class="float-right" color="success" outlined :disabled="loading" :loading="loading"
            @click="saveDetails">Save</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
  import rules from '../../../../utilities/validator';

  export default {
    name: 's-services-opted-garbage-service',
    props: {
      operation: {
        type: Object,
        default: {},
      },
      isDraft: {
        type: Boolean,
        default: false,
      },
      servicesOpted: {
        type: Array,
        default: []
      },
    },
    data() {
      return {
        loading: false,
        form: false,
        rules,
        serviceOptForm: {
          service_catalog_id: null,
          card_id: null,
          amount: 0,
          remarks: '',
          bill_type: "Garbage Disposal",
          metadata: {
            type: '',
            // cbm: 0,
            // unit: 0,
            // carton: 0,
            // additional_charges: 0,
            arrange_by_owner: false,
            tax: ''
          }
        },
        wasteTypes: ['Food / Plastic / Wood', 'Sludge / Chemical', 'Oily Rags / Ash', 'Pyrotechnic', 'Expired Medicines'],
        suppliers: [],
        taxes: ['0%', '9%']
      };
    },
    methods: {
      submit() {
        return this.$refs.form.validate();
      },
      handleOperationInit() {
        this.populateSuppliers();
      },
      // onFieldChange() {
      //   this.calculateAmount();
      // },
      initServiceOptedDetails() {
        const service = _.find(this.servicesOpted, {bill_type: 'Garbage Disposal'});
        if (service) {
          this.serviceOptForm = {
            ...service,
            amount: service.amount ? parseFloat(service.amount).toFixed(2) : '0.00',
          };
          if (service.metadata && service.metadata.arrange_by_owner !== undefined) {
            this.serviceOptForm.metadata.arrange_by_owner = service.metadata.arrange_by_owner;
          }
        }
      },
      populateSuppliers() {
        this.$api.service_catalogs.get_suppliers_list('Garbage Disposal')
          .then((response) => {
            this.suppliers = response.suppliers;
            this.serviceOptForm.service_catalog_id = response.service_catalog_id;
            this.initServiceOptedDetails();
            this.getServiceCatalogPricings();
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      getServiceCatalogPricings() {
        this.loading = true;
        this.$api.service_catalog_pricings.index()
          .then((response) => {
            this.pricings = response.service_catalog_pricings;
            this.loading = false;
          }).catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      // calculateAmount() {
      //   if (!this.serviceOptForm.card_id) {
      //     this.serviceOptForm.amount = 0;
      //     return;
      //   }

      //   const { cbm, type } = this.serviceOptForm.metadata;

      //   if (type === 'Food / Plastic / Wood' && cbm) {
      //     const cbmThreshold = this.getCBMCount(cbm);

      //     let cbm1Price = 0;
      //     let cbm2Price = 0;

      //     const cbm1Pricing = this.pricings.find(
      //       p => p.card_id === this.serviceOptForm.card_id &&
      //            p.metadata.type_of_waste === type &&
      //            this.getCBMCount(p.metadata.cbm) === 1
      //     );

      //     if (cbm1Pricing) {
      //       cbm1Price = parseFloat(cbm1Pricing.price) || 0;
      //     }

      //     const cbm2Pricing = this.pricings.find(p => {
      //       const cardIdMatches = p.card_id === this.serviceOptForm.card_id;
      //       const typeMatches = p.metadata.type_of_waste === type;
      //       const cbmThresholdMatches = p.metadata.cbm === cbmThreshold;

      //       return cardIdMatches && typeMatches && cbmThresholdMatches;
      //     });

      //     if (cbmThreshold === 1) {
      //       this.serviceOptForm.amount = (cbm1Price).toFixed(2);
      //     } else if (cbmThreshold === 2) {
      //       if (cbm2Pricing) {
      //         cbm2Price = parseFloat(cbm2Pricing.price) || 0;
      //       }
      //       const calculatedAmount = cbm1Price + (cbm2Price * (cbm - 5));
      //       this.serviceOptForm.amount = calculatedAmount.toFixed(2);
      //     }
      //   } else if (['Sludge / Chemical', 'Oily Rags / Ash'].includes(type)) {
      //     const matchingPricing = this.pricings.find(
      //       p => p.card_id === this.serviceOptForm.card_id &&
      //            p.metadata.type_of_waste === type
      //     );
      //     if (matchingPricing) {
      //       const price = parseFloat(matchingPricing.price) || 0;
      //       if (cbm <= 0.2) {
      //         this.serviceOptForm.amount = price.toFixed(2);
      //       } else {
      //         const units = Math.ceil(cbm / 0.2); // Number of 0.2 units
      //         const calculatedAmount = price * units;
      //         this.serviceOptForm.amount = calculatedAmount.toFixed(2);
      //       }
      //     } else {
      //       this.serviceOptForm.amount = (0).toFixed(2);
      //     }
      //   } else if (type === 'Pyrotechnic') {
      //     const matchingPricing = this.pricings.find(
      //       p => p.card_id === this.serviceOptForm.card_id &&
      //            p.metadata.type_of_waste === type
      //     );
      //     if (matchingPricing) {
      //       const price = parseFloat(matchingPricing.price) || 0;
      //       if (this.serviceOptForm.metadata.unit) {
      //         const calculatedAmount = price * this.serviceOptForm.metadata.unit;
      //         this.serviceOptForm.amount = calculatedAmount.toFixed(2);
      //       } else {
      //         this.serviceOptForm.amount = (0).toFixed(2);
      //       }
      //     } else {
      //       this.serviceOptForm.amount = (0).toFixed(2);
      //     }
      //   } else if (type === 'Expired Medicines') {
      //     const matchingPricing = this.pricings.find(
      //       p => p.card_id === this.serviceOptForm.card_id &&
      //            p.metadata.type_of_waste === type
      //     );
      //     if (matchingPricing) {
      //       const price = parseFloat(matchingPricing.price) || 0;
      //       if (this.serviceOptForm.metadata.carton) {
      //           const calculatedAmount = price * this.serviceOptForm.metadata.carton;
      //           this.serviceOptForm.amount = calculatedAmount.toFixed(2);
      //       } else {
      //           this.serviceOptForm.amount = (0).toFixed(2);
      //       }
      //     } else {
      //       this.serviceOptForm.amount = (0).toFixed(2);
      //     }
      //   } else {
      //     this.serviceOptForm.amount = (0).toFixed(2);
      //   }
      // },
      // getCBMCount(cbm) {
      //   if (cbm >= 6) return 2;
      //   if (cbm >= 1) return 1;
      //   return 0;
      // },
      saveDetails() {
        if(!this.submit()) return;
        this.loading = true;
        this.serviceOptForm = Object.assign(this.serviceOptForm, {
          operation_id: this.operation.id
        });
        this.$api.operation_billed_items.save_service(this.serviceOptForm)
          .then(() => {
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Garbage Disposal service updated successfully',
            });
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.loading = false));
      },
    },
    watch: {
      operation(value) {
        this.handleOperationInit();
      },
    },
    computed: {
      amountRules() {
        if (this.serviceOptForm.metadata.arrange_by_owner) {
          return [this.rules.negative];
        } else {
          if (this.isDraft || (this.operation && this.operation.is_draft)) {
            return [this.rules.required, this.rules.negative];
          } else {
            return [this.rules.negative];
          }
        }
      },
      supplierRules() {
        if (this.serviceOptForm.metadata.arrange_by_owner) {
          return [];
        } else {
          return [this.rules.required];
        }
      }
      // showCBMField() {
      //   return ['Food / Plastic / Wood', 'Sludge / Chemical', 'Oily Rags / Ash'].includes(this.serviceOptForm.metadata.type);
      // },
      // showUnitField() {
      //   return this.serviceOptForm.metadata.type === 'Pyrotechnic';
      // },
      // showCartonField() {
      //   return this.serviceOptForm.metadata.type === 'Expired Medicines';
      // },
    },
    created() {
      this.handleOperationInit();
    },
  };
</script>