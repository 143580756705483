<template>
  <v-container fluid>
    <v-form ref="form" v-model="form" @submit.prevent="submit">
      <v-row>
        <v-row v-for="(passengerBoat, index) in passengerBoats" :key="'passenger' + index">
          <v-col cols="12" class="pb-0">
            <span class="text-h6">Passenger Boat: #{{index + 1}}</span>
            <v-btn color="red" icon @click="removeServiceEntry(index, passengerBoat.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
            <v-switch
              dense
              hide-details
              v-model="passengerBoat.metadata.arrange_by_owner"
              label="Arrange By Principal?"
              class="mt-0 pt-0"
            ></v-switch>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!passengerBoat.metadata.arrange_by_owner" v-if="$root.hasAccess('can_manage_operation_amount_details')">
            <v-text-field
              label="Passenger Boat Service Charges"
              placeholder="Enter Passenger Boat Service Charges"
              outlined
              dense
              type="number"
              v-model="passengerBoat.amount"
              prepend-inner-icon="mdi-currency-usd"
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
            <v-select
              label="Day / Night Rate"
              placeholder="Select Day / Night Rate"
              outlined
              dense
              v-model="passengerBoat.metadata.dayornight"
              prepend-inner-icon="mdi-calendar-today"
              :items="day"
              @change="onFieldChange(passengerBoat)"
            ></v-select>
          </v-col> -->
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!passengerBoat.metadata.arrange_by_owner">
            <v-text-field
              label="No. of Trips"
              placeholder="Enter No. of trips"
              :rules="[rules.negative]"
              outlined
              dense
              type="number"
              v-model="passengerBoat.metadata.no_of_trips"
              prepend-inner-icon="mdi-car"
              @change="onFieldChange(passengerBoat)"
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
            <v-select
              label="Launch Location"
              placeholder="Select location"
              outlined
              dense
              v-model="passengerBoat.metadata.launch_location"
              prepend-inner-icon="mdi-anchor"
              :items="launchLocations"
              :rules="amountRules[index]"
            ></v-select>
          </v-col> -->
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!passengerBoat.metadata.arrange_by_owner">
            <v-select
              label="Launch Location"
              placeholder="Select location"
              outlined
              dense
              v-model="passengerBoat.metadata.launch_location"
              prepend-inner-icon="mdi-anchor"
              :items="launchLocations"
              @change="onFieldChange(passengerBoat)"
            ></v-select>
          </v-col>
          <!-- <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
            <v-select
              label="Anchorage Location"
              placeholder="Select location"
              outlined
              dense
              v-model="passengerBoat.metadata.anchorage_location"
              prepend-inner-icon="mdi-anchor"
              :items="anchorageLocation"
              :rules="amountRules[index]"
              @change="onFieldChange(passengerBoat)"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="passengerBoat.metadata.anchorage_location">
            <v-select
              label="Anchorage Sub Sectors"
              placeholder="Filter by sub-sectors"
              outlined
              dense
              :items="loadSubSectors(passengerBoat.metadata.anchorage_location)"
              v-model="passengerBoat.metadata.subSector"
              prepend-inner-icon="mdi-anchor"
              clearable
              @change="onFieldChange(passengerBoat)"
            ></v-select>
          </v-col> -->
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!passengerBoat.metadata.arrange_by_owner">
            <v-select
              label="Passenger Boat Supplier"
              placeholder="Select passenger boat supplier"
              outlined
              dense
              v-model="passengerBoat.card_id"
              prepend-inner-icon="mdi-account"
              :items="passengerSuppliers"
              :rules="supplierRules[index]"
              item-text="name"
              item-value="id"
              @change="onFieldChange(passengerBoat)"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!passengerBoat.metadata.arrange_by_owner" v-if="$root.hasAccess('can_manage_operation_amount_details')">
            <v-select
              label="Tax"
              placeholder="Select Tax"
              outlined
              dense
              v-model="passengerBoat.metadata.tax"
              prepend-inner-icon="mdi-cash-fast"
              :items="taxes"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col cols="12" class="pb-10">
            <v-textarea
              label="Remarks"
              placeholder="Enter Remarks"
              outlined
              dense
              hide-details
              :rows="2"
              v-model="passengerBoat.remarks"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-col cols="12">
          <v-btn color="primary" @click="addPassengerBoat">Add</v-btn>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-btn class="float-right" color="success" outlined :disabled="loading" :loading="loading"
            @click="saveDetails">Save</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
  import rules from '../../../../utilities/validator';
  import _ from 'lodash';

  export default {
    name: 's-services-opted-launch-boat-passenger-boat',
    props: {
      operation: {
        type: Object,
        default: {},
      },
      isDraft: {
        type: Boolean,
        default: false,
      },
      servicesOpted: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        loading: false,
        form: false,
        rules,
        passengerBoats: [],
        serviceOptFormPassenger: {
          service_catalog_id: null,
          card_id: null,
          amount: 0,
          bill_type: 'Passenger Boat',
          remarks: '',
          metadata: {
            // no_of_hours: 0,
            // price_per_hour: 0,
            launch_location: "",
            // anchorage_location: "",
            // subSector: "",
            // dayornight: "",
            no_of_trips: 0,
            arrange_by_owner: false,
            tax: ''
          }
        },
        passengerSuppliers: [],
        passengerServiceCatalogId: null,
        // launchLocations: [
        //   "Tanjong Pagar",
        //   "Keppel",
        //   "Brani",
        //   "Pasir Panjang",
        //   "Sembawang",
        //   "Jurong",
        // ],
        launchLocations: [
          "Marina South Pier",
          "West Coast Pier"
        ],
        // anchorageLocation: ['Eastern Sector','Western Sector','Jurong Sector'],
        // day: ["Day", "Night"],
        // subSectorItems: {
        //   "Eastern Sector": ["Changi Barge Temporary Holding Anchorage", "Changi General Purposes Anchorage", "Man-of-War Anchorage", "Eastern Bunkering A Anchorage", "Eastern Bunkering B Anchorage", "Eastern Petroleum C Anchorage", "Small Craft B Anchorage", "Small Craft A Anchorage", "Eastern Petroleum B Anchorage", "Eastern Special Purposes A Anchorage", "Eastern Bunkering C Anchorage", "Eastern Holding A Anchorage", "Eastern Petroleum A Anchorage", "Eastern Anchorage", "Eastern Holding B Anchorage", "Eastern Holding C Anchorage"],
        //   "Western Sector": ["Western Quarantine and Immigration Anchorage", "Western Anchorage", "Western Petroleum A Anchorage", "Western Holding Anchorage", "Western Petroleum B Anchorage", "Raffles Reserved Anchorage", "Raffles Petroleum Anchorage", "Selat Pauh Anchorage", "Selat Pauh Petroleum Anchorage"],
        //   "Jurong Sector": ["Sudong Petroleum Holding Anchorage", "Sudong Explosive Anchorage", "Sudong Special Purpose Anchorage", "Sudong Holding Anchorage", "Very Large Crude Carrier Anchorage"]
        // },
        pricings: [],
        taxes: ['0%', '9%']
      };
    },
    methods: {
      removeServiceEntry(index, serviceId) {
        if (!serviceId) {
          this.passengerBoats.splice(index, 1);
          return;
        }
        this.loading = true;
        this.$api.operation_billed_items.remove_service_entry(serviceId, { index })
          .then(() => {
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Service entry removed successfully',
            });
            this.passengerBoats.splice(index, 1);
          })
          .catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          })
          .finally(() => {
            this.loading = false;
          });
      },
      submit() {
        return this.$refs.form.validate();
      },
      onFieldChange(passengerBoat) {
        this.calculateAmount(passengerBoat);
      },
      addPassengerBoat() {
        this.passengerBoats.push(JSON.parse(JSON.stringify(this.serviceOptFormPassenger)));
      },
      handleOperationInit() {
        this.populateSuppliers();
      },
      initServiceOptedDetails() {
        const passengerServices = _.filter(this.servicesOpted, {bill_type: 'Passenger Boat'});
        if (passengerServices.length) {
          const newServices = passengerServices.filter(service => 
              service.card_id !== null || (service.card_id === null && service.metadata.arrange_by_owner === true)
            );
          if (newServices.length > 0) {
            this.passengerBoats = newServices.map(service => {
              service.metadata.arrange_by_owner = service.metadata.arrange_by_owner || false;

              return {
                ...service,
                amount: parseFloat(service.amount).toFixed(2),
              };
            });
          } else {
            this.passengerBoats = [JSON.parse(JSON.stringify(this.serviceOptFormPassenger))];
          }
        } else {
          this.addPassengerBoat();
        }
      },
      populateSuppliers() {
        Promise.all([
            this.$api.service_catalogs.get_suppliers_list('Passenger Boat')
              .then((response) => {
                this.passengerSuppliers = response.suppliers;
                this.passengerServiceCatalogId = response.service_catalog_id;
              })
          ]).then(() => {
            this.initServiceOptedDetails();
            this.getPopulateBoatPricing();
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      getPopulateBoatPricing() {
        this.loading = true;

        this.$api.passenger_boat_pricings.index()
          .then((response) => {
            this.pricings = response.passenger_boat_pricing;
          })
          .catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => {
            this.loading = false;
          });
      },
      // calculateAmount(passengerBoat) {
      //   if (!passengerBoat.card_id) {
      //     passengerBoat.amount = 0;
      //     return;
      //   }

      //   const matchingPricing = this.pricings.find(pricing => {
      //     const cardIdMatches = pricing.card_id === passengerBoat.card_id;
      //     const launchLocation = pricing.launch_location === passengerBoat.metadata.launch_location;
      //     const anchorageLocation = pricing.anchorage_location === passengerBoat.metadata.anchorage_location;
      //     const day = pricing.day === passengerBoat.metadata.dayornight;

      //     let matches = cardIdMatches && launchLocation && anchorageLocation && day ;

      //     if (passengerBoat.metadata.launch_location) {
      //       matches = matches && pricing.sub_sector === passengerBoat.metadata.subSector;
      //     }

      //     return matches;
      //   });

      //   if (matchingPricing) {
      //     const price = parseFloat(matchingPricing.price) || 0;
      //     const fuelSurcharge = parseFloat(matchingPricing.fuel_surcharge) || 0;
      //     passengerBoat.amount = (price + fuelSurcharge).toFixed(2);
      //   } else {
      //     passengerBoat.amount = 0;
      //   }
      // },

      calculateAmount(passengerBoat) {
        if (!passengerBoat.card_id) {
          passengerBoat.amount = 0;
          return;
        }

        const isMatching =
          Number(passengerBoat.card_id) === 31 &&
          passengerBoat.metadata.launch_location === "Marina South Pier" &&
          Number(passengerBoat.metadata.no_of_trips) === 2;
        if (isMatching) {
          const price = 100;
          const fuelSurcharge = 20;
          passengerBoat.amount = (price + fuelSurcharge).toFixed(2);
        } else {
          passengerBoat.amount = 0;
        }
      },
      saveDetails() {
        if(!this.submit()) return;
        this.loading = true;

        this.passengerBoats = this.passengerBoats.map(service => {
          service.service_catalog_id = this.passengerServiceCatalogId;
          service.operation_id = this.operation.id;

          return service;
        });
        Promise.all([
            ...[...this.passengerBoats].map(service => {
              const apiType = service.id ? "update" : "save_service";
              return this.$api.operation_billed_items[apiType](service, true)
            })
          ]).then(() => {
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Passenger Boat service updated successfully',
            });
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.loading = false));
      }
    },
    watch: {
      operation(value) {
        this.handleOperationInit();
      }
    },
    computed: {
      amountRules() {
        return this.passengerBoats.map(service => {
          if (service.metadata.arrange_by_owner) {
            return [this.rules.negative];
          } else {
            if (this.isDraft || (this.operation && this.operation.is_draft)) {
              return [this.rules.required, this.rules.negative];
            } else {
              return [this.rules.negative];
            }
          }
        });
      },
      supplierRules() {
        return this.passengerBoats.map(service => {
          if (service.metadata.arrange_by_owner) {
            return [];
          } else {
            return [this.rules.required];
          }
        });
      }
      // loadSubSectors() {
      //   return (anchorageLocation) => {
      //     return this.subSectorItems[anchorageLocation] || [];
      //   };
      // },
    },
    created() {
      this.handleOperationInit();
    },
  };
</script>