<template>
  <v-container fluid>
    <v-speed-dial
      v-model="fab"
      bottom
      right
      fixed
      open-on-hover
      :direction="'top'"
      :transition="'slide-y-reverse-transition'"
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="primary" dark fab>
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            color="green"
            v-on="on"
            @click="dialogForm = {action: 'Create Job', showForm: true,isDraft: false};currentOperation={};"
          >
            <v-icon>mdi-clipboard-plus</v-icon>
          </v-btn>
        </template>
        <span>Open Job</span>
      </v-tooltip>
      <v-tooltip left v-if="$root.hasAccess('can_create_vessel')">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            color="indigo"
            v-on="on"
            @click="dialogForm = {action: 'Create PDA Job', showForm: true,isDraft: true};currentOperation={};"
          >
            <v-icon>mdi-clipboard-text</v-icon>
          </v-btn>
        </template>
        <span>Create PDA</span>
      </v-tooltip>
    </v-speed-dial>
    <v-row class="pb-5">
      <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
        <v-select
          label="Filter Jobs"
          placeholder="PDA / FDA"
          outlined
          dense
          v-model="filterType"
          prepend-inner-icon="mdi-filter"
          :items="filterTypes"
        ></v-select>
      </v-col>
      <v-col v-if="filterType === 'All'" cols="12" sm="6" md="4" lg="4" class="pb-0">
        <v-switch
          dense
          hide-details
          v-model="showCompleted"
          label="Show Completed Jobs"
          class="mt-0 pt-2"
        ></v-switch>
      </v-col>
    </v-row>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          :no-data-text="'No data found'"
          :no-results-text="'No results found'"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="formattedHeaders"
        :items="filteredItems"
        :search="search"
        :loading="loading"
        :sort-by="['id']"
        :sort-desc="[true]"
      >
        <template v-slot:item.job_name="{ item }">
          <v-btn :loading="completedLoading == item.job_name" :disabled="completedLoading == item.job_name" text @click="dialogForm = {action: 'Edit Job', showForm: true, isDraft: item.is_draft};currentOperation=Object.assign({}, item)">
            <span><u>{{item.job_name}}</u></span>
          </v-btn>
        </template>
        <template v-slot:item.fda="{ item }">
          <v-btn icon @click="generateReport(item.id, false)" v-if="!item.is_draft">
            <v-icon>mdi-file-document</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.pda="{ item }">
          <v-btn v-if="item.is_draft" icon @click="generateReport(item.id, true)">
            <v-icon>mdi-file-document</v-icon>
          </v-btn>
          <v-btn v-else-if="item.clone_id" icon @click="generateReport(item.clone_id, true)">
            <v-icon>mdi-file-document</v-icon>
          </v-btn>
          <v-btn v-else icon @click="generateReport(item.id, true)">
            <v-icon>mdi-file-document</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.vessel_report="{ item }">
          <v-btn icon @click="$router.push({name: 'showOperationReport', params: {id: item.id}})" v-if="!item.is_draft">
            <v-icon>mdi-file-document</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.job="{ item }">
          <template v-if="item.clone_id">
            Converted to FDA
          </template>
          <template v-else>
            <v-btn color="success" small @click="convertToJob(item)">
              PDA Accepted
            </v-btn>
          </template>
        </template>
        <template v-slot:item.complete="{ item }">
          <v-btn color="success" small @click="markJobComplete(item)">
            Mark As Billed
          </v-btn>
        </template>
        <template v-slot:item.button="{ item }">
          <template v-if="item.is_draft && !item.clone_id">
            <v-btn style="background-color: #273978; color: white;" small @click="convertToJob(item)">
              PDA Accepted
            </v-btn>
          </template>
          <template v-else-if="!item.is_draft">
            <v-btn color="success" small @click="markJobComplete(item)">
              Mark As Billed
            </v-btn>
          </template>
        </template>
        <template v-slot:item.delete="{ item }">
          <v-btn
            icon
            color="red"
            @click="deleteJob(item)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <template v-slot:progress>
          <div class="full-width text-center ma-4">
            <v-progress-circular
              indeterminate
              color="primary"
              :size="60"
              :width="5"
            ></v-progress-circular>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <operation-form 
      :operation="currentOperation"
      :action="dialogForm.action"
      :showForm="dialogForm.showForm"
      :isDraft="dialogForm.isDraft"
      @closeDialog="resetDialog()"
      @completeJob="markJobComplete($event)"
      @showOperation="viewJob = true;currentOperation=Object.assign({}, $event)" />
  </v-container>
</template>

<script>
  import OperationForm from './blocks/OperationForm';

  export default {
    name: 's-operations',
    components: {
      OperationForm,
    },
    data() {
      return {
        filterTypes: ['All','Proforma Disbursement Account Jobs', 'Final Disbursement Account Jobs'],
        filterType: 'All',
        showCompleted: false,
        fab: false,
        dialogForm: {
          showForm: false,
          action: '',
        },
        completedLoading: null,
        viewJob: false,
        currentOperation: {},
        search: '',
        loading: false,
        headers: [
          {text: 'Job_Number', value: 'job_name', align: 'start'},
          {text: 'Vessel', value: 'vessel_name', align: 'center'},
          {text: 'Customer', value: 'customer_name', align: 'center'},
          {text: 'Job Scope', value: 'job_scope', align: 'center'},
          {text: 'Incharge', value: 'supervisor_name', align: 'center'},
          {text: 'Job Start Date', value: 'job_start_date', align: 'center'},
        ],
        items: [],
        cloned_item: [],
      };
    },
    computed: {
      formattedHeaders() {
        const headers = [...this.headers];

        if (this.filterType === 'Proforma Disbursement Account Jobs') {
          headers.push({ text: 'PDA', value: 'pda', align: 'center' });
          if (this.$root.hasAccess('can_manage_actions')) {
            headers.push({ text: 'Convert To Job', value: 'job', align: 'center' });
            headers.push({ text: 'Delete', value: 'delete', align: 'center' });
          }
        } else if (this.filterType === 'All') {
          if (this.showCompleted) {
            return headers;
          } else {
            headers.push(
              { text: 'PDA', value: 'pda', align: 'center' },
              { text: 'FDA', value: 'fda', align: 'center' },
              { text: 'Vessel Report', value: 'vessel_report', align: 'center' }
            );
            if (this.$root.hasAccess('can_manage_actions')) {
              headers.push({ text: 'Actions', value: 'button', align: 'center' });
              headers.push({ text: 'Delete', value: 'delete', align: 'center' });
            }
          }
        } else {
          headers.push(
            { text: 'PDA', value: 'pda', align: 'center' },
            { text: 'FDA', value: 'fda', align: 'center' },
            { text: 'Vessel Report', value: 'vessel_report', align: 'center' }
          );
          if (this.$root.hasAccess('can_manage_actions')) {
            headers.push({
              text: 'Mark Job Complete',
              value: 'complete',
              align: 'center',
            });
            headers.push({ text: 'Delete', value: 'delete', align: 'center' });
          }
        }
        return headers;
      },
      filteredItems() {
        if (this.filterType === 'Proforma Disbursement Account Jobs') {
          return this.items.filter(x => x.is_draft);
        } else if (this.filterType === 'Final Disbursement Account Jobs') {
          return this.items.filter(x => !x.is_draft && x.status === 'Open');
        } else if (this.filterType === 'All') {
          if (this.showCompleted) {
            return this.items.filter(x => x.status === 'Completed');
          } else {
            return this.items.filter(x => x.status === 'Open');
          }
        }
      },
    },
    methods: {
      deleteJob(operation) {
        if (confirm(`Are you sure you want to delete Job#${operation.id}?`)) {
          console.log('11',operation.id)
          this.$api.operations.destroy(operation.id)
            .then(() => {
              this.$store.dispatch('setAlert', {
                show: true,
                message: `Job#${operation.id} has been deleted successfully.`,
              });
              this.getOperations();
            })
            .catch(err => {
              this.$store.dispatch('setAlert', {
                status: 'error',
                show: true,
                message: err.message,
              });
            });
        }
      },
      generateReport(operationId, isPda) {
        this.$api.operations.generate_report(operationId, isPda)
          .then(response => {
            const url = window.URL.createObjectURL(response.data)

            const link = document.createElement('a')
            link.href = url
            link.setAttribute('target', '_blank')
            document.body.appendChild(link)
            link.click()
            link.remove()
          }).catch(err => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => {});
      },
      convertToJob(operation) {
        this.$api.operations.job_clone(operation.id)
          .then(response => {
            this.cloned_item = response.cloned_job[0];
            this.$store.dispatch('setAlert', {
              show: true,
              message: `Job Cloned successfully`,
            });
            this.getOperations();
            this.dialogForm.action = 'Edit Job';
            this.dialogForm.showForm = true;
            this.dialogForm.isDraft = this.cloned_item.is_draft;
            this.currentOperation = Object.assign({}, this.cloned_item);
          }).catch(err => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.completedLoading = null));
      },
      markJobComplete(operation) {
        if (this.completedLoading == operation.id) return;

        this.completedLoading = operation.id;
        if (confirm("Completed jobs cannot be edited, Are you sure to complete this job?")) {
          this.$api.operations.completeJob(operation.id)
            .then(response => {
              this.$store.dispatch('setAlert', {
                show: true,
                message: `Job#${operation.id} marked as completed`,
              });
              this.getOperations();
              this.resetDialog();
            }).catch(err => {
              this.$store.dispatch('setAlert', {
                status: 'error',
                show: true,
                message: err.message,
              });
            }).finally(() => (this.completedLoading = null));
        } else {
          this.completedLoading = null;
        }
      },
      resetDialog() {
        this.dialogForm = {showForm: false, action: '',isDraft: false};
        this.getOperations();
      },
      getOperations() {
        this.loading = true;
        this.$api.operations.index()
          .then((response) => {
            this.items = response.operations;
            this.loading = false;
          }).catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      }
    },
    created() {
      if (this.$route.query.createMode) {
        this.dialogForm = {
          action: `Create ${this.$route.query.createMode} Job`,
          showForm: true,
          isDraft: this.$route.query.createMode === 'PDA'
        };
        this.currentOperation = Object.assign({}, {
          vessel_id: parseInt(this.$route.query.vesselId),
          card_id: parseInt(this.$route.query.customerId),
          eta: this.$route.query.eta,
          etd: this.$route.query.etd
        });
      }
      this.getOperations();
    },
  };
</script>