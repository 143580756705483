<template>
  <v-container fluid>
    <v-card>
      <v-row class="mx-6">
        <v-col v-for="(services, category) in servicesGrouped" :key="category" cols="12">
          <h3>{{ category }}</h3>
          <v-row class="ml-3">
            <v-col v-for="service in services" :key="service.id" cols="12" class="p-5">
              <v-checkbox
                v-model="selectedServices"
                :value="service.id"
                :label="service.name"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-btn style="background-color: #273978; color: white;" class="float-right" outlined :disabled="loading" :loading="loading"
            @click="saveDetails">Save</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import rules from '../../../utilities/validator';
import _ from 'lodash';
export default {
  name: 's-services-opted',
  props: {
    operation: {
      type: Object,
      default: () => ({}),
    },
    isDraft: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules,
      services: [],
      servicesGrouped: {},
      catalog_id: [],
      selectedServices: [],
      select: [],
      selectAll: false,
      loading: false,
    };
  },
  methods: {
    handleOperationInit() {
      this.populateServices();
      this.fetchServiceCatalog();
    },
    populateServices() {
      this.$api.operation_billed_items.get_services_list(this.operation.id)
        .then((response) => {
           this.selectedServices = response.service_catalogs;
        }).catch((err) => {
          this.$store.dispatch('setAlert', {
            status: 'error',
            show: true,
            message: err.message,
          });
        });
    },
    fetchServiceCatalog() {
      this.$api.service_catalogs.getServiceCatalogName()
        .then((response) => {
          // Create an array of objects containing both service names and catalog IDs
          const servicesWithIds = response.service_catalog_names.map((name, index) => ({
            name,
            id: response.service_catalog_ids[index],
            category: response.categories[index],
          }));

          // Sort the categories alphabetically
          const sortedCategories = _.sortBy(_.uniq(servicesWithIds.map(service => service.category)));

          // Initialize an empty object to hold grouped services
          const groupedServices = {};

          // Group services by category
          sortedCategories.forEach(category => {
            const servicesInCategory = servicesWithIds.filter(service => service.category === category);
            groupedServices[category] = _.sortBy(servicesInCategory, 'name');
          });

          this.servicesGrouped = groupedServices;

          this.services = servicesWithIds.map(service => service.name);
          this.catalog_id = servicesWithIds.map(service => service.id);

          const agencyFeesIndex = this.services.findIndex(service => service === 'Agency Fees');
          if (agencyFeesIndex !== -1) {
            this.selectedServices.push(this.catalog_id[agencyFeesIndex]);
          }
        }).catch((err) => {
          this.$store.dispatch('setAlert', {
            status: 'error',
            show: true,
            message: err.message,
          });
        });
    },

    saveDetails() {
      this.loading = true;
      const selectedIndex = [];

      this.services.forEach((service, index) => {
        const serviceId = this.catalog_id[index];
        if (this.selectedServices.includes(serviceId)) {
          const serviceDetails = {
            id: serviceId,
            name: service,
            card_id: null,
            amount: null
          };

          if (service === 'Agency Fees') {
            serviceDetails.card_id = 190;
            if(this.operation.job_scope === "Owner’s Matter") {
              serviceDetails.amount = 350.00;
            } else if (this.operation.job_scope === "Charterer") {
              serviceDetails.amount = 350.00;
            } else if (this.operation.job_scope === "Cargo Operation") {
              serviceDetails.amount = 1500.00;
            } else if (this.operation.job_scope === "Handover / takeover") {
              serviceDetails.amount = 150.00;
            } else if (this.operation.job_scope === "Full Agent") {
              serviceDetails.amount = 500.00;
            } else if (this.operation.job_scope === "OPL(outside port limits)") {
              serviceDetails.amount = 350.00;
            } else if (this.operation.job_scope === "Shipyard / Dry Dock") {
              serviceDetails.amount = 500.00;
            } else if (this.operation.job_scope === "Sheriff Agent") {
              serviceDetails.amount = 500.00;
            } else if (this.operation.job_scope === "Crew Service") {
              serviceDetails.amount = 500.00;
            }
          }
          selectedIndex.push(serviceDetails);
        }
      });
      const requestData = {
        operationId: this.operation.id,
        selectedIndex
      };
      this.$api.operation_billed_items.createService(requestData)
        .then((response) => {
          this.selectedServices = response.selectedServices;
          this.loading = false;
          this.$emit('nextStep', 3);
        })
        .catch((err) => {
          this.$store.dispatch('setAlert', {
            status: 'error',
            show: true,
            message: err.message,
          });
          this.loading = false;
        });
    },
  },
  watch: {
    operation(value) {
      this.handleOperationInit();
    }
  },
  created() {
    this.handleOperationInit();
  }
};
</script>

<style>
  .p-5 {
    padding: 5px;
  }
</style>