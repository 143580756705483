<template>
  <v-container fluid>
    <v-form ref="form" v-model="form" @submit.prevent="submit">
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-switch
            dense
            hide-details
            v-model="arrivalVisaApplication.metadata.arrange_by_owner"
            label="Arrange By Principal?"
            class="mt-0 pt-0"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!arrivalVisaApplication.metadata.arrange_by_owner">
          <v-text-field
            label="Total No. Of Application"
            placeholder="Enter No. Of Application"
            :rules="[rules.negative]"
            outlined
            dense
            type="number"
            v-model="arrivalVisaApplication.metadata.no_of_application"
            prepend-inner-icon="mdi-account"
            @input="calculateArrivalVisaPrice"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!arrivalVisaApplication.metadata.arrange_by_owner" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-text-field
            label="Price Per Application"
            placeholder="Enter Price Per Application"
            :rules="[rules.negative]"
            outlined
            dense
            type="number"
            v-model="arrivalVisaApplication.metadata.price_per_application"
            prepend-inner-icon="mdi-currency-usd"
            @input="calculateArrivalVisaPrice"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!arrivalVisaApplication.metadata.arrange_by_owner" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-text-field
            label="Service Charges"
            placeholder="Enter Service Charges"
            outlined
            dense
            type="number"
            v-model="arrivalVisaApplication.amount"
            prepend-inner-icon="mdi-currency-usd"
            :hint="arrivalVisaHintText"
            :rules="amountRules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!arrivalVisaApplication.metadata.arrange_by_owner">
          <v-select
            label="Supplier"
            placeholder="Select Supplier"
            outlined
            dense
            v-model="arrivalVisaApplication.card_id"
            prepend-inner-icon="mdi-account"
            :disabled="isDisabled"
            :items="visaApplicationSuppliers"
            :rules="supplierRules"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!arrivalVisaApplication.metadata.arrange_by_owner" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-select
            label="Tax"
            placeholder="Select Tax"
            outlined
            dense
            v-model="arrivalVisaApplication.metadata.tax"
            prepend-inner-icon="mdi-cash-fast"
            :items="taxes"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-textarea
            label="Remarks"
            placeholder="Enter Remarks"
            outlined
            dense
            hide-details
            :rows="2"
            v-model="arrivalVisaApplication.remarks"
          ></v-textarea>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-btn class="float-right" color="success" outlined :disabled="loading" :loading="loading"
            @click="saveDetails">Save</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
  import rules from '../../../../utilities/validator';
  import _find from 'lodash/find';

  export default {
    name: 's-services-opted-crew-change-service',
    props: {
      operation: {
        type: Object,
        default: {},
      },
      isDraft: {
        type: Boolean,
        default: false,
      },
      servicesOpted: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        loading: false,
        form: false,
        isDisabled: false,
        rules,
        arrivalVisaApplication: {
          service_catalog_id: null,
          card_id: null,
          amount: 0,
          bill_type: 'Visa Application Crew Sign On',
          remarks: '',
          metadata: {
            no_of_application: 0,
            price_per_application: 0,
            arrange_by_owner: false,
            tax: ''
          }
        },
        visaApplicationSuppliers: [],
        sinodaSupplier: [],
        arrival_visa_calculated_amount: 0,
        taxes: ['0%', '9%']
      };
    },
    methods: {
      submit() {
        return this.$refs.form.validate();
      },
      handleOperationInit() {
        this.populateSuppliers();
      },
      initServiceOptedDetails() {
        const arrivalVisaApplication = _.find(this.servicesOpted, {bill_type: 'Visa Application Crew Sign On'});
        if (arrivalVisaApplication) {
          this.arrivalVisaApplication = { ...arrivalVisaApplication };
          this.arrivalVisaApplication.amount = parseFloat(this.arrivalVisaApplication.amount || 0).toFixed(2);
          this.arrival_visa_calculated_amount = parseInt(this.arrivalVisaApplication.metadata.no_of_application || 0) * parseInt(this.arrivalVisaApplication.metadata.price_per_application || 0);
          if (arrivalVisaApplication.metadata && arrivalVisaApplication.metadata.arrange_by_owner !== undefined) {
            this.arrivalVisaApplication.metadata.arrange_by_owner = arrivalVisaApplication.metadata.arrange_by_owner;
          }
        }
      },
      calculateArrivalVisaPrice() {
        this.arrival_visa_calculated_amount = parseInt(this.arrivalVisaApplication.metadata.no_of_application) * parseInt(this.arrivalVisaApplication.metadata.price_per_application);
        this.arrivalVisaApplication.amount = this.arrival_visa_calculated_amount;
      },
      populateSuppliers() {
        this.$api.service_catalogs.get_suppliers_list('Visa Application Crew Sign On')
          .then((response) => {
            this.visaApplicationSuppliers = response.suppliers;
            this.sinodaSupplier = _find(this.visaApplicationSuppliers, { name: 'Sinoda' });
            if (this.sinodaSupplier) {
              this.arrivalVisaApplication.card_id = this.sinodaSupplier.id;
              this.isDisabled = true;
            }
            this.arrivalVisaApplication.service_catalog_id = response.service_catalog_id;
            this.initServiceOptedDetails();
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      saveDetails() {
        if(!this.submit()) return;
        this.loading = true;
        this.arrivalVisaApplication = Object.assign(this.arrivalVisaApplication, {
          operation_id: this.operation.id
        });

        Promise.all([
            this.$api.operation_billed_items.save_service(this.arrivalVisaApplication)
          ]).then(() => {
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Visa Application Crew Sign On service updated successfully',
            });
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.loading = false));
      }
    },
    computed: {
      amountRules() {
        if (this.arrivalVisaApplication.metadata.arrange_by_owner) {
          return [this.rules.negative];
        } else {
          if (this.isDraft || (this.operation && this.operation.is_draft)) {
            return [this.rules.required, this.rules.negative];
          } else {
            return [this.rules.negative];
          }
        }
      },
      arrivalVisaHintText() {
        if (Number(this.arrivalVisaApplication.amount) !== this.arrival_visa_calculated_amount) {
          return `Service charges differs from calculated amount \S$${this.arrival_visa_calculated_amount}`;
        } else {
          return "";
        }
      },
      supplierRules() {
        if (this.arrivalVisaApplication.metadata.arrange_by_owner) {
          return [];
        } else {
          return [this.rules.required];
        }
      }
    },
    watch: {
      operation(value) {
        this.handleOperationInit();
      },
    },
    created() {
      this.handleOperationInit();
    },
  };
</script>