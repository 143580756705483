<template>
  <div class="text-center">
    <v-dialog v-model="showForm" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{action}}
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('closeDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form ref="form" v-model="form" @submit.prevent="submit">
          <v-card-text class="pb-0">
            <v-select
              label="Service Name"
              placeholder="Select a service Name"
              :rules="[rules.required]"
              outlined
              dense
              v-model="serviceCatalogForm.name"
              prepend-inner-icon="mdi-dns"
              :items="service_categories"
            ></v-select>
            <v-select
              label="Service Category"
              placeholder="Select a Service Category"
              :rules="[rules.required]"
              outlined
              dense
              v-model="serviceCatalogForm.category"
              prepend-inner-icon="mdi-group"
              :items="service_group"
            ></v-select>
            <v-switch dense class="mt-0" v-model="serviceCatalogForm.is_active" label="Active / Inactive">
            </v-switch>
            <v-select
              label="Service Suppliers"
              placeholder="Select suppliers"
              outlined
              :items="filteredSuppliers"
              v-model="serviceCatalogForm.suppliers"
              hide-details
              prepend-inner-icon="mdi-account"
              item-text="name"
              item-value="id"
              multiple
              dense
            >
              <template v-slot:prepend-item>
                <v-text-field
                  v-model="searchQuery"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Search suppliers..."
                  clearable
                  @input="filterSuppliers"
                  dense
                ></v-text-field>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index === 0 || index === 1">
                  <span>{{ item.name }}</span>
                </v-chip>
                <span
                  v-if="index === 2"
                  class="grey--text text-caption"
                >
                  (+{{ serviceCatalogForm.suppliers.length - 2 }} others)
                </span>
              </template>
            </v-select>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center">
            <v-btn color="primary" outlined @click="formSubmit()" 
              :disabled="loading" :loading="loading">
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import rules from '../../../utilities/validator';

  export default {
    name: 's-service-catalog-form',
    props: {
      action: {
        type: String,
        required: true,
      },
      showForm: {
        type: Boolean,
        default: false,
      },
      suppliers: Array,
      service_catalog: {
        type: Object,
        default: {},
      },
    },
    data() {
      let serviceCatalogObj = {
        name: '',
        category: '',
        is_active: true,
        suppliers: [],
      };

      return {
        service_categories: ['Port Clearance And Dues' ,'Pilotage', 'Tug Boat Arrival', 'Tug Boat Departure', 'Supply Boat', 'Passenger Boat', 'Freshwater', 'Bunkering', 'Cash To Master', 'Supplies', 'Logistics', 'Deslopping', 'Class Surveyor', 'Bunker Surveyor', 'Underwater Cleaning', 'Underwater Inspection', 'Repair', 'Transport Service', 'Crew Sign On Service', 'Visa Application Crew Sign On', 'Medical Consultation', 'Hotel Booking Crew Sign On', 'Crew Sign Off Service', 'Visa Application Crew Sign Off', 'Hotel Booking Crew Sign Off', 'B/L Fee', 'Agency Fees', 'Handling of Spares', 'Garbage Disposal', 'Telecommunication', 'SSCEC', 'Writ of Summons', 'Pre Vetting Inspection', 'Pre Purchase Inspection', 'Others', 'Fumigation', 'Coordination Fee', 'Hotel Booking Superintendent or Owners representatives', 'Porterage', 'Courier', 'MPA Gas Free Inspection Fees'],
        service_group: ['Agency', 'Port', 'Crewing', 'Logistics', 'Boats', 'Operations', 'Inspection and Surveys', 'Others'],
        form: false,
        rules,
        loading: false,
        tmpForm: Object.assign({}, serviceCatalogObj),
        serviceCatalogForm: Object.assign({}, serviceCatalogObj),
        searchQuery: '',
        filteredSuppliers: []
      };
    },
    methods: {
      filterSuppliers() {
        const query = this.searchQuery.toLowerCase();
        this.filteredSuppliers = this.suppliers.filter((supplier) =>
          supplier.name.toLowerCase().includes(query)
        );
      },
      submit() {
        return this.$refs.form.validate();
      },
      formSubmit() {
        if(!this.submit()) return;
        this.loading = true;
        if (!this.service_catalog.id) this.createService();
        else this.updateService();
      },
      createService() {
        this.$api.service_catalogs.create(this.serviceCatalogForm)
          .then((response) => {
            this.serviceCatalogForm = Object.assign({}, this.tmpForm);
            this.$refs.form.reset();
            this.$emit('formSubmitted');
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Service created successfully',
            });
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.loading = false));
      },
      updateService() {
        let cloneObject = Object.assign({}, this.serviceCatalogForm);
        cloneObject.suppliers = cloneObject.suppliers.filter(x => x);
        if (cloneObject.suppliers.length) {
          if (typeof(cloneObject.suppliers[0]) == 'object'){
            cloneObject.suppliers = cloneObject.suppliers.map(x => x.id);
            this.serviceCatalogForm.suppliers = cloneObject.suppliers;
          }
        }
        this.$api.service_catalogs.update(this.serviceCatalogForm)
          .then((response) => {
            this.serviceCatalogForm = Object.assign({}, this.tmpForm);
            this.$refs.form.reset();
            this.$emit('formSubmitted');
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Service updated successfully',
            });
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.loading = false));
      },
    },
    watch: {
      service_catalog(value) {
        this.serviceCatalogForm = Object.assign({}, value);
        if (!value.id) this.$refs.form && this.$refs.form.reset();
      },
      suppliers: {
        immediate: true,
        handler(newSuppliers) {
          this.filteredSuppliers = newSuppliers;
        },
      },
    },
  };
</script>