<template>
  <v-container fluid>
    <v-form ref="form" v-model="form" @submit.prevent="submit">
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-switch
            dense
            hide-details
            v-model="serviceOptForm.metadata.arrange_by_owner"
            label="Arrange By Principal?"
            class="mt-0 pt-0"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!serviceOptForm.metadata.arrange_by_owner" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-text-field
            label="Freshwater Total Service Charges"
            placeholder="Enter Freshwater Total Service Service Charges"
            outlined
            dense
            type="number"
            v-model="serviceOptForm.amount"
            prepend-inner-icon="mdi-currency-usd"
            :hint="hintText"
            :rules="amountRules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!serviceOptForm.metadata.arrange_by_owner">
          <v-text-field
            label="Number Of Litres"
            placeholder="Enter Number Of Litres"
            :rules="[rules.negative]"
            outlined
            dense
            type="number"
            v-model="serviceOptForm.metadata.no_of_litres"
            prepend-inner-icon="mdi-scale"
            @change="onFieldChange()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!serviceOptForm.metadata.arrange_by_owner">
          <v-select
            label="Supplier"
            placeholder="Select a supplier"
            outlined
            dense
            v-model="serviceOptForm.card_id"
            prepend-inner-icon="mdi-account"
            :items="suppliers"
            :rules="supplierRules"
            item-text="name"
            item-value="id"
            @change="onFieldChange()"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!serviceOptForm.metadata.arrange_by_owner" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-select
            label="Tax"
            placeholder="Select Tax"
            outlined
            dense
            v-model="serviceOptForm.metadata.tax"
            prepend-inner-icon="mdi-cash-fast"
            :items="taxes"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-textarea
            label="Remarks"
            placeholder="Enter Remarks"
            outlined
            dense
            hide-details
            :rows="2"
            v-model="serviceOptForm.remarks"
          ></v-textarea>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-btn class="float-right" color="success" outlined :disabled="loading" :loading="loading"
            @click="saveDetails">Save</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
  import rules from '../../../../utilities/validator';
  import _ from 'lodash';

  export default {
    name: 's-services-opted-freshwater',
    props: {
      operation: {
        type: Object,
        default: {},
      },
      isDraft: {
        type: Boolean,
        default: false,
      },
      servicesOpted: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        loading: false,
        form: false,
        rules,
        serviceOptForm: {
          service_catalog_id: null,
          card_id: null,
          amount: 0,
          bill_type: "Freshwater",
          remarks: '',
          metadata: {
            no_of_litres: 0,
            arrange_by_owner: false,
            tax: ''
            // distance_charges: 0,
            // waiting_time_charges: 0
          }
        },
        suppliers: [],
        totalAmount: 0,
        pricings: [],
        taxes: ['0%', '9%']
      };
    },
    methods: {
      submit() {
        return this.$refs.form.validate();
      },
      handleOperationInit() {
        this.populateSuppliers();
      },
      onFieldChange() {
        if (this.serviceOptForm.metadata.no_of_litres || this.serviceOptForm.card_id) {
          this.calculateAmount();
        }
      },
      initServiceOptedDetails() {
        const service = _.find(this.servicesOpted, { bill_type: 'Freshwater' });
        if (service) {
          this.serviceOptForm = { ...this.serviceOptForm, ...service };
          this.serviceOptForm.amount = service.amount
            ? parseFloat(service.amount).toFixed(2)
            : '0.00';

          this.totalAmount = this.serviceOptForm.amount;

          if (service.metadata && service.metadata.arrange_by_owner !== undefined) {
            this.serviceOptForm.metadata.arrange_by_owner = service.metadata.arrange_by_owner;
          }
        }
      },
      populateSuppliers() {
        this.$api.service_catalogs.get_suppliers_list('Freshwater')
          .then((response) => {
            this.suppliers = response.suppliers;
            this.serviceOptForm.service_catalog_id = response.service_catalog_id;
            this.initServiceOptedDetails();
            this.getServiceCatalogPricings();
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      getServiceCatalogPricings() {
        this.loading = true;
        this.$api.service_catalog_pricings.index()
          .then((response) => {
            this.pricings = response.service_catalog_pricings;
            this.loading = false;
          }).catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      calculateAmount() {
        const litreRanges = [
          { min: 0, max: 50000, value: 1 },
          { min: 50001, max: 100000, value: 2 },
          { min: 100001, max: 150000, value: 3 },
          { min: 150001, max: 200000, value: 4 },
          { min: 200001, max: 250000, value: 5 },
          { min: 250001, max: 300000, value: 6 },
          { min: 300001, max: 350000, value: 7 },
          { min: 350001, max: 400000, value: 8 },
          { min: 400001, max: Infinity, value: 9 },
        ];

        let remainingLitres = this.serviceOptForm.metadata.no_of_litres;
        let totalAmount = 0;

        litreRanges.forEach((range) => {
          if (remainingLitres <= 0) return;

          const litresInRange = Math.min(remainingLitres, range.max - range.min);
          const litresInThousands = litresInRange / 1000;
          const pricing = this.pricings.find(
            (p) => p.metadata.quantity === range.value && p.card_id === this.serviceOptForm.card_id
          );

          if (pricing) {
            totalAmount += litresInThousands * pricing.price;
          }

          remainingLitres -= litresInRange;
        });

        this.totalAmount = totalAmount.toFixed(2);
        if (this.serviceOptForm.metadata.no_of_litres || this.serviceOptForm.card_id) {
          this.serviceOptForm.amount = this.totalAmount;
        }
      },
      saveDetails() {
        if(!this.submit()) return;
        this.loading = true;
        this.serviceOptForm = Object.assign(this.serviceOptForm, {
          operation_id: this.operation.id,
        });
        this.$api.operation_billed_items.save_service(this.serviceOptForm)
          .then(() => {
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Freshwater service updated successfully',
            });
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.loading = false));
      },
    },
    computed: {
      hintText() {
        if (Number(this.serviceOptForm.amount) !== Number(this.totalAmount)) {
          return `Freshwater total service charges differs from calculated amount \S$${this.totalAmount}`;
        } else {
          return "";
        }
      },
      amountRules() {
        if (this.serviceOptForm.metadata.arrange_by_owner) {
          return [this.rules.negative];
        } else {
          if (this.isDraft || (this.operation && this.operation.is_draft)) {
            return [this.rules.required, this.rules.negative];
          } else {
            return [this.rules.negative];
          }
        }
      },
      supplierRules() {
        if (this.serviceOptForm.metadata.arrange_by_owner) {
          return [];
        } else {
          return [this.rules.required];
        }
      }
    },
    watch: {
      operation(value) {
        this.handleOperationInit();
      }
    },
    created() {
      this.handleOperationInit();
    },
  };
</script>