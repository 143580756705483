<template>
  <v-container fluid>
    <v-form ref="form" v-model="form" @submit.prevent="submit">
      <v-row>
        <v-row v-for="(service, index) in transportServices" :key="'service-' + index">
          <v-col cols="12" class="pb-0">
            <span class="text-h6">Transport Service: #{{index + 1}}</span>
            <v-btn color="red" icon @click="removeTransportServiceEntry(index, service.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
            <v-switch
              dense
              hide-details
              v-model="service.metadata.arrange_by_owner"
              label="Arrange By Principal?"
              class="mt-0 pt-0"
            ></v-switch>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!service.metadata.arrange_by_owner">
            <v-text-field
              label="No. of Trips"
              placeholder="Enter No. of trips"
              :rules="[rules.negative]"
              outlined
              dense
              type="number"
              v-model="service.metadata.no_of_trips"
              prepend-inner-icon="mdi-car"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!service.metadata.arrange_by_owner" v-if="$root.hasAccess('can_manage_operation_amount_details')">
            <v-text-field
              label="Transport Service Charges"
              placeholder="Enter Transport Service Charges"
              :rules="amountRules[index]"
              outlined
              dense
              type="number"
              v-model="service.amount"
              prepend-inner-icon="mdi-currency-usd"
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
            <v-switch
              dense
              hide-details
              v-model="service.metadata.meet_greet_booked"
              label="Meet & Greet Booked?"
              class="mt-1"
            ></v-switch>
          </v-col> -->
          <!-- <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
            <v-select
              label="Source Location"
              placeholder="Select location"
              outlined
              dense
              v-model="service.metadata.source_location"
              prepend-inner-icon="mdi-anchor"
              :items="sourceLocation"
              :rules="amountRules[index]"
              @change="onFieldChange(service)"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
            <v-select
              label="Destination Location"
              placeholder="Select location"
              outlined
              dense
              v-model="service.metadata.destination_location"
              prepend-inner-icon="mdi-anchor"
              :items="sourceLocation"
              :rules="amountRules[index]"
              @change="onFieldChange(service)"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
            <v-text-field
              label="No. of PAX"
              placeholder="Enter No. of PAX"
              :rules="[rules.negative]"
              outlined
              dense
              type="number"
              v-model="service.metadata.persons"
              prepend-inner-icon="mdi-account-group"
              @change="onFieldChange(service)"
            ></v-text-field>
          </v-col> -->
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!service.metadata.arrange_by_owner">
            <v-select
              label="Transport Service Supplier"
              placeholder="Select Transport Service Supplier"
              outlined
              dense
              v-model="service.card_id"
              prepend-inner-icon="mdi-account"
              :items="transportSuppliers"
              :rules="supplierRules[index]"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!service.metadata.arrange_by_owner" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-select
            label="Tax"
            placeholder="Select Tax"
            outlined
            dense
            v-model="service.metadata.tax"
            prepend-inner-icon="mdi-cash-fast"
            :items="taxes"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
          <v-col cols="12" class="pb-4">
            <v-textarea
              label="Remarks"
              placeholder="Enter Remarks"
              outlined
              dense
              hide-details
              :rows="2"
              v-model="service.remarks"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-col cols="12">
          <v-btn color="primary" @click="addTransportService">Add</v-btn>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-btn class="float-right" color="success" outlined :disabled="loading" :loading="loading"
            @click="saveDetails">Save</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
  import rules from '../../../../utilities/validator';
  import _find from 'lodash/find';

  export default {
    name: 's-services-opted-crew-change-service',
    props: {
      operation: {
        type: Object,
        default: {},
      },
      isDraft: {
        type: Boolean,
        default: false,
      },
      servicesOpted: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        loading: false,
        form: false,
        isDisabled: false,
        rules,
        transportServices: [],
        serviceOptFormTransport: {
          service_catalog_id: null,
          card_id: null,
          amount: 0,
          bill_type: 'Transport Service',
          remarks: '',
          metadata: {
            no_of_trips: 0,
            arrange_by_owner: false,
            // meet_greet_booked: 0,
            // source_location: '',
            // destination_location: '',
            // persons: 0,
            tax: ''
          }
        },
        // sourceLocation: ['Brani Terminal', 'CBD', 'Changi Airport', 'Harbourfront Center', 'Hotel', 'Jurong', 'Keppel Terminal', 'Keppel Tuas', 'Marina South Pier', 'Mega yard at Tuas', 'Pasir Panjang Terminal', 'PSA', 'Sembawang', 'Tuas Area Shipyard', 'Weat Coast Pier', 'Woodlands'],
        transportSuppliers: [],
        pricings: [],
        taxes: ['0%', '9%']
      };
    },
    methods: {
      removeTransportServiceEntry(index, serviceId) {
        if (!serviceId) {
          this.transportServices.splice(index, 1);
          return;
        }
        this.loading = true;
        this.$api.operation_billed_items.remove_service_entry(serviceId, { index })
          .then(() => {
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Service entry removed successfully',
            });
            this.transportServices.splice(index, 1);
          })
          .catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          })
          .finally(() => {
            this.loading = false;
          });
      },
      submit() {
        return this.$refs.form.validate();
      },
      // onFieldChange(service) {
      //   this.calculateAmount(service);
      // },
      addTransportService() {
        const newTransportService = JSON.parse(JSON.stringify(this.serviceOptFormTransport));
        this.transportServices.push(newTransportService);
      },
      handleOperationInit() {
        this.populateSuppliers();
      },
      initServiceOptedDetails() {
        const transportService = _.filter(this.servicesOpted, {bill_type: 'Transport Service'});
        if (transportService.length) {
          const newServices = transportService.filter(service => 
              service.card_id !== null || (service.card_id === null && service.metadata.arrange_by_owner === true)
            );
          if (newServices.length > 0) {
            this.transportServices = newServices.map(service => {
              service.metadata.arrange_by_owner = service.metadata.arrange_by_owner || false;

              return {
                ...service,
                amount: parseFloat(service.amount).toFixed(2),
              };
            });
          } else {
            this.transportServices = [JSON.parse(JSON.stringify(this.serviceOptFormTransport))];
          }
        } else {
          this.addTransportService();
        }
      },
      populateSuppliers() {
        Promise.all([
          this.$api.service_catalogs.get_suppliers_list('Transport Service')
            .then((response) => {
              this.transportSuppliers = response.suppliers;
              this.serviceOptFormTransport.service_catalog_id = response.service_catalog_id;
            })
        ]).then(() => {
          this.initServiceOptedDetails();
          this.getServiceCatalogPricings();
        }).catch((err) => {
          this.$store.dispatch('setAlert', {
            status: 'error',
            show: true,
            message: err.message,
          });
        });
      },
      getServiceCatalogPricings() {
        this.loading = true;
        this.$api.service_catalog_pricings.index()
          .then((response) => {
            this.pricings = response.service_catalog_pricings;
            this.loading = false;
          }).catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      // calculateAmount(service) {
      //   if (!service.card_id || service.metadata.source_location === undefined || service.metadata.destination_location === undefined) {
      //     service.amount = 0;
      //     return;
      //   }

      //   const personsThreshold = this.getPaxCount(service.metadata.persons);
      //   const matchingPricing = this.pricings.find(pricing => {
      //     const cardIdMatches = pricing.card_id === service.card_id;

      //     if (!pricing.metadata) {
      //       return false;
      //     }
      //     const sourceLocation = pricing.metadata.source_location === service.metadata.source_location;
      //     const destinationLocation = pricing.metadata.destination_location === service.metadata.destination_location;
      //     const persons = pricing.metadata.persons === personsThreshold;
      //     let matches = cardIdMatches && sourceLocation && destinationLocation && persons;
      //     return matches;
      //   });

      //   if (matchingPricing) {
      //     let price = parseFloat(matchingPricing.price) || 0;

      //     // Check if midnight_charge is present in metadata and add it to the price
      //     if (matchingPricing.metadata && matchingPricing.metadata.midnight_charge) {
      //       const midnightCharge = parseFloat(matchingPricing.metadata.midnight_charge) || 0;
      //       price += midnightCharge;
      //     }

      //     service.amount = price.toFixed(2);
      //   } else {
      //     service.amount = (0).toFixed(2);
      //   }
      // },
      // getPaxCount(persons) {
      //   if (persons >= 6) return 6;
      //   if (persons >= 1) return 5;
      //   return 0;
      // },
      saveDetails() {
        if(!this.submit()) return;
        this.loading = true;

        this.transportServices = this.transportServices.map(service => {
          service.service_catalog_id = this.serviceOptFormTransport.service_catalog_id;
          service.operation_id = this.operation.id;

          return service;
        });

        Promise.all([
          ...[...this.transportServices].map(service => {
            const apiType = service.id ? "update" : "save_service";
            return this.$api.operation_billed_items[apiType](service, true)
          })
        ]).then(() => {
          this.$store.dispatch('setAlert', {
            status: 'success',
            show: true,
            message: 'Transport service updated successfully',
          });
        }).catch((err) => {
          this.$store.dispatch('setAlert', {
            status: 'error',
            show: true,
            message: err.message,
          });
        }).finally(() => (this.loading = false));
      }
    },
    computed: {
      amountRules() {
        return this.transportServices.map(service => {
          if (service.metadata.arrange_by_owner) {
            return [this.rules.negative];
          } else {
            if (this.isDraft || (this.operation && this.operation.is_draft)) {
              return [this.rules.required, this.rules.negative];
            } else {
              return [this.rules.negative];
            }
          }
        });
      },
      supplierRules() {
        return this.transportServices.map(service => {
          if (service.metadata.arrange_by_owner) {
            return [];
          } else {
            return [this.rules.required];
          }
        });
      }
    },
    watch: {
      operation(value) {
        this.handleOperationInit();
      },
    },
    created() {
      this.handleOperationInit();
    },
  };
</script>