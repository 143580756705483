<template>
  <v-container fluid>
    <v-form ref="form" v-model="form" @submit.prevent="submit">
      <v-row>
        <v-row v-for="(service, index) in bunkerServices" :key="'service-' + index">
          <v-col cols="12" class="pb-0">
            <span class="text-h6">Bunker Service: #{{ index + 1 }}</span>
            <v-btn color="red" icon @click="removeBunkerServiceEntry(index, service.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
            <v-switch
              dense
              hide-details
              v-model="service.metadata.arrange_by_owner"
              label="Arrange By Principal?"
              class="mt-0 pt-0"
            ></v-switch>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!service.metadata.arrange_by_owner">
            <v-select
              label="Type"
              placeholder="Select Type"
              outlined
              dense
              v-model="service.metadata.fuel_type"
              prepend-inner-icon="mdi-gas-station"
              :items="fuelType"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!service.metadata.arrange_by_owner">
            <v-text-field
              label="No. of Metric Tons"
              placeholder="Enter No. of Metric Tons"
              :rules="[rules.negative]"
              outlined
              dense
              type="number"
              v-model="service.metadata.no_of_metric_tons"
              prepend-inner-icon="mdi-weight"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!service.metadata.arrange_by_owner" v-if="$root.hasAccess('can_manage_operation_amount_details')">
            <v-text-field
              label="Total Price"
              placeholder="Enter Total Price"
              outlined
              dense
              type="number"
              v-model="service.amount"
              prepend-inner-icon="mdi-currency-usd"
              :hint="hintText"
              :rules="amountRules[index]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!service.metadata.arrange_by_owner">
            <v-select
              label="Grade"
              placeholder="Select a grade"
              outlined
              dense
              v-model="service.metadata.grade"
              prepend-inner-icon="mdi-blur"
              :items="grades"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!service.metadata.arrange_by_owner">
            <v-select
              label="Bunker Supplier"
              placeholder="Select Bunker supplier"
              outlined
              dense
              v-model="service.card_id"
              prepend-inner-icon="mdi-account"
              :items="bunkerSuppliers"
              :rules="supplierRules[index]"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!service.metadata.arrange_by_owner" v-if="$root.hasAccess('can_manage_operation_amount_details')">
            <v-select
              label="Tax"
              placeholder="Select Tax"
              outlined
              dense
              v-model="service.metadata.tax"
              prepend-inner-icon="mdi-cash-fast"
              :items="taxes"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col cols="12" class="pb-5" v-show="!service.metadata.arrange_by_owner">
            <v-switch
              dense
              hide-details
              v-model="service.metadata.advanced"
              label="Advanced?"
              class="mt-0 pt-0"
            ></v-switch>
          </v-col>
          <v-row v-show="service.metadata.advanced">
            <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
              <v-datetime-picker
                ref="bunkerBargeAlongside"
                label="Bunker Barge Alongside"
                v-model="service.metadata.bunker_barge_alongside"
                :textFieldProps="{dense: true, readonly: true, 'prepend-inner-icon': 'mdi-calendar', outlined: true, id: 'bunkerBargeAlongsideField'}"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-calendar-clock</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
              <v-datetime-picker
                ref="bunkerSurveyorOnboard"
                label="Bunker surveyor onboard"
                v-model="service.metadata.bunker_surveyor_onboard"
                :textFieldProps="{dense: true, readonly: true, 'prepend-inner-icon': 'mdi-calendar', outlined: true, id: 'bunkerSurveyorOnboardField'}"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-calendar-clock</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
              <v-datetime-picker
                ref="hoseConnected"
                label="Hose connected"
                v-model="service.metadata.hose_connected"
                :textFieldProps="{dense: true, readonly: true, 'prepend-inner-icon': 'mdi-calendar', outlined: true, id: 'hoseConnectedField'}"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-calendar-clock</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
              <v-datetime-picker
                ref="startPump"
                label="Start pump"
                v-model="service.metadata.start_pump"
                :textFieldProps="{dense: true, readonly: true, 'prepend-inner-icon': 'mdi-calendar', outlined: true, id: 'startPumpField'}"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-calendar-clock</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
              <v-datetime-picker
                ref="stopPump"
                label="Stop pump"
                v-model="service.metadata.stop_pump"
                :textFieldProps="{dense: true, readonly: true, 'prepend-inner-icon': 'mdi-calendar', outlined: true, id: 'stopPumpField'}"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-calendar-clock</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
              <v-datetime-picker
                ref="hoseDisconnected"
                label="Hose disconnected"
                v-model="service.metadata.hose_disconnected"
                :textFieldProps="{dense: true, readonly: true, 'prepend-inner-icon': 'mdi-calendar', outlined: true, id: 'hoseDisconnectedField'}"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-calendar-clock</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
              <v-datetime-picker
                ref="bargeCastOff"
                label="Barge cast off"
                v-model="service.metadata.barge_cast_off"
                :textFieldProps="{dense: true, readonly: true, 'prepend-inner-icon': 'mdi-calendar', outlined: true, id: 'bargeCastOffField'}"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-calendar-clock</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
              <v-datetime-picker
                ref="bunkerSurveyorDisembark"
                label="Bunker surveyor disembark"
                v-model="service.metadata.bunker_surveyor_disembark"
                :textFieldProps="{dense: true, readonly: true, 'prepend-inner-icon': 'mdi-calendar', outlined: true, id: 'bunkerSurveyorDisembarkField'}"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-calendar-clock</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
              <v-text-field
                label="Vessel figure"
                placeholder="Enter Vessel figure"
                outlined
                dense
                type="number"
                v-model="service.metadata.vessel_figure"
                suffix="m³"
                prepend-inner-icon="mdi-ferry"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
              <v-text-field
                label="EBDN figure"
                placeholder="Enter EBDN figure"
                outlined
                dense
                type="number"
                v-model="service.metadata.ebdn_figure"
                suffix="m³"
                prepend-inner-icon="mdi-note-outline"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-col cols="12" class="pb-0">
            <v-textarea
              label="Remarks"
              placeholder="Enter Remarks"
              outlined
              dense
              hide-details
              :rows="2"
              v-model="service.remarks"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-col cols="12" class="pt-10">
          <v-btn color="primary" @click="addBunkerService">Add</v-btn>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-btn class="float-right" color="success" outlined :disabled="loading" :loading="loading"
            @click="saveDetails">Save</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
  import rules from '../../../../utilities/validator';
  import _ from 'lodash';

  export default {
    name: 's-services-opted-bunker-service',
    props: {
      operation: {
        type: Object,
        default: {},
      },
      isDraft: {
        type: Boolean,
        default: false,
      },
      servicesOpted: {
        type: Array,
        default: []
      },
    },
    data() {
      return {
        loading: false,
        form: false,
        rules,
        grades: [
          'High',
          'Intermediate',
          'Medium',
          'Low',
          'Very Low Sulphur',
          'Not Indicated'
        ],
        fuelType: [
          'Fuel Oil',
          'Gas Oil',
          'Lube Oil',
          'Disel Oil'
        ],
        bunkerServices: [],
        bunkerServiceOptForm: {
          service_catalog_id: null,
          card_id: null,
          amount: 0,
          bill_type: "Bunkering",
          remarks: '',
          metadata: {
            arrange_by_owner: false,
            no_of_metric_tons: 0,
            fuel_type: '',
            grade: '',
            advanced: false,
            tax: '',
            bunker_barge_alongside: null,
            bunker_surveyor_onboard: null,
            hose_connected: null,
            start_pump: null,
            stop_pump: null,
            hose_disconnected: null,
            barge_cast_off: null,
            bunker_surveyor_disembark: null,
            vessel_figure: '',
            ebdn_figure: ''
          }
        },
        calculated_amount: 0,
        bunkerSuppliers: [],
        taxes: ['0%', '9%']
      };
    },
    methods: {
      removeBunkerServiceEntry(index, serviceId) {
        if (!serviceId) {
          this.bunkerServices.splice(index, 1);
          return;
        }
        this.loading = true;
        this.$api.operation_billed_items.remove_service_entry(serviceId, { index })
          .then(() => {
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Service entry removed successfully',
            });
            this.bunkerServices.splice(index, 1);
          })
          .catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          })
          .finally(() => {
            this.loading = false;
          });
      },
      addBunkerService() {
        const newBunkerService = JSON.parse(JSON.stringify(this.bunkerServiceOptForm));
        this.bunkerServices.push(newBunkerService);
      },
      submit() {
        return this.$refs.form.validate();
      },
      handleOperationInit() {
        this.populateSuppliers();
      },
      initServiceOptedDetails() {
        const bunkerService = _.filter(this.servicesOpted, {bill_type: 'Bunkering'});
        if (bunkerService.length) {
          const newServices = bunkerService.filter(service => 
              service.card_id !== null || (service.card_id === null && service.metadata.arrange_by_owner === true)
            );
          if (newServices.length > 0) {
            this.bunkerServices = newServices.map(service => {
              service.metadata.arrange_by_owner = service.metadata.arrange_by_owner || false;

              return {
                ...service,
                amount: parseFloat(service.amount).toFixed(2),
                metadata: {
                  ...service.metadata,
                  bunker_barge_alongside: service.metadata.bunker_barge_alongside
                    ? new Date(service.metadata.bunker_barge_alongside)
                    : null,
                  bunker_surveyor_onboard: service.metadata.bunker_surveyor_onboard
                    ? new Date(service.metadata.bunker_surveyor_onboard)
                    : null,
                  hose_connected: service.metadata.hose_connected
                    ? new Date(service.metadata.hose_connected)
                    : null,
                  start_pump: service.metadata.start_pump
                    ? new Date(service.metadata.start_pump)
                    : null,
                  stop_pump: service.metadata.stop_pump
                    ? new Date(service.metadata.stop_pump)
                    : null,
                  hose_disconnected: service.metadata.hose_disconnected
                    ? new Date(service.metadata.hose_disconnected)
                    : null,
                  barge_cast_off: service.metadata.barge_cast_off
                    ? new Date(service.metadata.barge_cast_off)
                    : null,
                  bunker_surveyor_disembark: service.metadata.bunker_surveyor_disembark
                    ? new Date(service.metadata.bunker_surveyor_disembark)
                    : null,
                }
              };
            });
          } else {
            this.bunkerServices = [JSON.parse(JSON.stringify(this.bunkerServiceOptForm))];
          }
        } else {
          this.addBunkerService();
        }
        // if (bunkerService.length) {
        //   this.bunkerServiceOptForm = { ...bunkerService };
        //   this.bunkerServiceOptForm.metadata.no_of_metric_tons = parseFloat(this.bunkerServiceOptForm.metadata.no_of_metric_tons || 0).toFixed(2);
        //   this.bunkerServiceOptForm.amount = parseFloat(this.bunkerServiceOptForm.amount || 0).toFixed(2);
          // this.calculated_amount = parseInt(this.bunkerServiceOptForm.metadata.price_per_metric_ton || 0) * parseInt(this.bunkerServiceOptForm.metadata.no_of_metric_tons || 0);
        // }
      },
      // calculateBunkerPrice() {
      //   this.calculated_amount = parseInt(this.bunkerServiceOptForm.metadata.price_per_metric_ton) * parseInt(this.bunkerServiceOptForm.metadata.no_of_metric_tons);
      //   this.bunkerServiceOptForm.amount = this.calculated_amount;
      // },
      populateSuppliers() {
        this.$api.service_catalogs.get_suppliers_list('Bunkering')
          .then((response) => {
            this.bunkerSuppliers = response.suppliers;
            this.bunkerServiceOptForm.service_catalog_id = response.service_catalog_id;
            this.initServiceOptedDetails();
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      saveDetails() {
        if (!this.submit()) return;

        this.loading = true;
        this.bunkerServices = this.bunkerServices.map(service => {
          service.service_catalog_id = this.bunkerServiceOptForm.service_catalog_id;
          service.operation_id = this.operation.id;

          return service;
        });
        Promise.all([
          ...[...this.bunkerServices].map(service => {
            const apiType = service.id ? "update" : "save_service";
            return this.$api.operation_billed_items[apiType](service, true)
          })
        ]).then(() => {
          this.$store.dispatch('setAlert', {
            status: 'success',
            show: true,
            message: 'Bunker supplies updated successfully',
          });
        }).catch((err) => {
          this.$store.dispatch('setAlert', {
            status: 'error',
            show: true,
            message: err.message,
          });
        }).finally(() => (this.loading = false));
      }
    },
    computed: {
      hintText() {
        if (Number(this.bunkerServiceOptForm.amount) !== this.calculated_amount) {
          return `Total price differs from calculated amount \S$${this.calculated_amount}`;
        } else {
          return "";
        }
      },
      amountRules() {
        return this.bunkerServices.map(service => {
          if (service.metadata.arrange_by_owner) {
            return [this.rules.negative];
          } else {
            if (this.isDraft || (this.operation && this.operation.is_draft)) {
              return [this.rules.required, this.rules.negative];
            } else {
              return [this.rules.negative];
            }
          }
        });
      },
      supplierRules() {
        return this.bunkerServices.map(service => {
          if (service.metadata.arrange_by_owner) {
            return [];
          } else {
            return [this.rules.required];
          }
        });
      }
    },
    watch: {
      operation(value) {
        this.handleOperationInit();
      }
    },
    created() {
      this.handleOperationInit();
    },
  };
</script>