<template>
  <div class="d-flex justify-center">
    <div class="text-center content-placeholder">
      <img
        class="d-inline-block logo-w"
        :src="SinodaLogo"
      />
      <v-card flat>
        <v-row class="py-4">
          <v-col cols="12" class="text-center">
            <div class="text-h6 font-weight-bold section-title">
              Job Details
            </div>
          </v-col>
        </v-row>
        <div class="mb-5" style="border: 1px solid;"></div>
        <ShowJobDetails :operation="operation" :vessel-info="vesselInfo"/>

        <v-row class="py-4" style="margin-top: 200px;">
          <v-col cols="12" class="text-center">
            <div class="text-h6 font-weight-bold section-title">
              {{docType}} Details
            </div>
          </v-col>
        </v-row>
        <ShowServicesInvoice :operation="operation" :vessel-info="vesselInfo" />
      </v-card>
    </div>
  </div>
</template>

<script>
  import ShowJobDetails from './blocks/ShowJobDetails';
  import ShowVesselArrivalInformation from './blocks/ShowVesselArrivalInformation';
  import ShowServicesInvoice from './blocks/ShowServicesInvoice';
  import axios from 'axios';
  import SinodaLogo from 'images/SinodaLetterHead.png';

  export default {
    name: 's-operation-show',
    components: {
      ShowJobDetails,
      ShowVesselArrivalInformation,
      ShowServicesInvoice,
    },
    data() {
      let vesselInfoObj = {
        captain_mail_id: '',
        coming_from: '',
        going_to: '',
        last_port: '',
        next_port: '',
        fwd: '',
        aft: '',
        ht: '',
        freeboard: '',
        cargo_type: '',
        qty_volume: '',
        eta_next_port: null,
        vessel_contact: '',
        anchorage: {
          pilot_station: '',
          sector: '',
          subSector: '',
          arrival_bearth: null,
          gangway_down: null,
          bearthing_station: null,
          sail_berth: null
        },
      };

      return {
        imageData: '',
        loading: false,
        SinodaLogo,
        tmpForm: Object.assign({}, vesselInfoObj),
        vesselInfo: Object.assign({}, vesselInfoObj),
        operation: {},
        tab: null,
        items: [
          {label: 'Job Details', icon: 'mdi-anchor', component: 'show-job-details'},
          {label: 'Vessel Information', icon: 'mdi-ferry', component: 'show-vessel-arrival-information'},
          {label: 'Services Opted', icon: 'mdi-gas-station', component: 'show-services-opted'},
        ],
      };
    },
    computed: {
      docType() {
        if (this.$route.params.type === 'Quote') {
          return "Proforma Disbursement Account";
        }
        return "Final Disbursement Account";
      },
    },
    methods: {
      getOperations() {
        this.loading = true;
        this.$api.operations.index({ operation_id: this.$route.params.id })
          .then((response) => {
            this.operation = response.operations[0];
            this.loading = false;
            this.$root.pageTitle = `Operation ${this.$route.params.type} #${this.operation.id}`;
          }).catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      }
    },
    beforeDestroy() {
      this.$root.pageTitle = '';
    },
    created() {
      if(!this.$root.hasAccess('can_manage_operation_amount_details')) {
        this.$store.dispatch('setAlert', {
          status: 'error',
          show: true,
          message: 'Not have access to this page',
        });
        this.$router.push({name: 'home'});
      }
      this.$root.pageTitle = 'Operation';
      this.getOperations();
    },
  };
</script>

<style scoped>
  .content-placeholder {
    max-width: 837px;
    border: 1px solid #ffffff;
    margin: 48px;
    box-decoration-break: clone;
  }
  @media (max-width: 600px) {
    .content-placeholder {
      max-width: 837px;
      border: 1px solid #ffffff;
      margin: 4px;
      box-decoration-break: clone;
    }
    .logo-w {
      width: 75%;
    }
  }
  .logo-w {
    width: 50%;
  }
  .section-title {
    background-color: #e6e6e6 !important;
    padding: 4px;
  }
</style>